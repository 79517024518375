import React from 'react';
import PropTypes from 'prop-types';

import './style.css';

const ButtonLink = ({ disabled, onClick, caption, cssClass, isBlack }) => {
  const buttonColor = isBlack ? '#333333' : '#FFCB02';
  return (
    <button
      type="button"
      className={`button-link ${cssClass}`}
      disabled={disabled}
      onClick={onClick}
      style={{
        backgroundColor: buttonColor,
        background: 'none',
        cursor: 'pointer',
        textDecoration: 'underline',
        padding: '0',
        border: 'none',
      }}
    >
      {caption}
    </button>
  );
};

ButtonLink.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  caption: PropTypes.string.isRequired,
  cssClass: PropTypes.string,
  isBlack: PropTypes.bool,
};

ButtonLink.defaultProps = {
  disabled: false,
  onClick: undefined,
  cssClass: '',
  isBlack: true,
};

export default ButtonLink;
