import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';

import Master from '../Master';
import StatsTile from './StatsTile';
import TransLogs from './TransLogs';
import { FormatDate, DateTimeUTC } from '../../utils/Functions';
import RedCard from './RedCard';
import Charges from './Charges';
import Tenancy from './Tenancy';

const Dashboard = ({ user }) => {
  // token
  const userToken = user ? user.token : {};
  const requestConfig = {
    headers: {
      Authorization: `Bearer ${userToken}`,
      'Content-Type': 'application/json',
    },
  };
  const date = new Date();
  const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  // state
  const [todayRent, setTodayRent] = useState(0);
  const [monthRent, setMonthRent] = useState(0);
  const [properties, setProperties] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [units, setUnits] = useState([]);
  const [totalUnits, setTotalUnits] = useState(0);
  const [totalOccupied, setTotalOccupied] = useState(0);
  const [occupancy, setOccupancy] = useState(0);
  const [arrears, setArrears] = useState(0);
  const [incoming, setIncoming] = useState([]);
  const [outgoing, setOutgoing] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        let url = `/byuser?status=active&user_id=${user.id}`;
        if (user && user.type === 'admin') {
          url = '?where={"status":"active"}&order={"name":"ASC"}';
        }
        const {
          data: { response },
        } = await axios.get(
          `${process.env.REACT_APP_API_URL}properties${url}`,
          requestConfig,
        );
        setProperties(response);
        loadOccupancy(response);
        loadArrears(response);
        getUnits(response);
      } catch (_) {
        //
      }
    })();
  }, []);

  /**
   *
   * @param {[]} payments
   */
  function mapPayments(payments) {
    let rentToday = 0;
    let rentMonth = 0;
    payments.forEach((payment) => {
      rentMonth += parseFloat(payment.amount);
      if (FormatDate(payment.createdat) === FormatDate(new Date())) {
        rentToday += parseFloat(payment.amount);
      }
    });
    setMonthRent(rentMonth);
    setTodayRent(rentToday);
  }

  /**
   *
   * @param {[]} property
   */
  async function getUnits(property) {
    const propertyIds = property.map((item) => {
      return item.id;
    });
    let urlUnits;
    // get units
    if (user.type === 'admin') {
      urlUnits = `${process.env.REACT_APP_API_URL}units?take=10000`;
    } else {
      urlUnits = `${process.env.REACT_APP_API_URL
        }units?take=10000&where={"property_id":"in::${propertyIds.join()}"}`;
    }
    try {
      const {
        data: { response: unitsInfo },
      } = await axios.get(urlUnits, requestConfig);
      setUnits(unitsInfo);
      loadTenants(unitsInfo);
    } catch (_) {
      //
    }
  }

  /**
   *
   * @param {[]} unit
   */
  async function loadTenants(unit) {
    try {
      const unitIds = unit.map((item) => {
        return item.id;
      });
      if (unitIds.length > 0) {
        let urlTenants;
        // get units
        if (user.type === 'admin') {
          urlTenants = `${process.env.REACT_APP_API_URL}tenants?take=10000`;
        } else {
          urlTenants = `${process.env.REACT_APP_API_URL
            }tenants?take=10000&where={"unit_id":"in::${unitIds.join()}"}`;
        }
        const {
          data: { response },
        } = await axios.get(urlTenants, requestConfig);
        loadTenancy(unit, response);
        setTenants(response);
        loadPayments(response);
      }
    } catch (_) {
      //
    }
  }

  /**
   *
   * @param {[]} tenantsInfo
   */
  async function loadPayments(tenantsInfo) {
    try {
      const tenantsIds = tenantsInfo.map((item) => {
        return item.id;
      });
      let filter;
      if (user.type === 'admin') {
        filter = `"createdat":"btwn::${DateTimeUTC(firstDay, false)},${DateTimeUTC(lastDay, true)}",
        "purpose": "rent"`;
      } else {
        filter = `"createdat":"btwn::${DateTimeUTC(firstDay, false)},${DateTimeUTC(lastDay, true)}",
        "purpose": "rent","tenant_id": "in::${tenantsIds.join()}"`;
      }
      const url = `${process.env.REACT_APP_API_URL}payments?where={${filter}}`;
      const {
        data: { response },
      } = await axios.get(url, requestConfig);
      mapPayments(response);
    } catch (_) {
      // log errors somewhere
    }
  }

  /**
   *
   * @param {[]} propertyInfo
   */
  async function loadOccupancy(propertyInfo) {
    try {
      const propertyIds = propertyInfo
        .map((item) => {
          return item.id;
        })
        .join();
      let filter = '"status":"active"';
      if (user.type !== 'admin') {
        filter = `${filter},"id":"in::${propertyIds}"`;
      }
      const urlOccupancy = `${process.env.REACT_APP_API_URL}property/occupancy?where={${filter}}`;
      const {
        data: { response: property },
      } = await axios.get(urlOccupancy, requestConfig);
      let $TotalUnits = 0;
      let $TotalOccupied = 0;
      property.forEach((item) => {
        const occupiedUnits = item.occupied_units || 0;
        const totalUnit = item.units || 0;
        $TotalUnits += totalUnit;
        $TotalOccupied += occupiedUnits;
      });
      setTotalUnits($TotalUnits);
      setTotalOccupied($TotalOccupied);
      const $occupancy = ($TotalOccupied / $TotalUnits) * 100;
      setOccupancy($occupancy.toFixed(2));
    } catch (_) {
      //
    }
  }

  /**
   *
   * @param {[]} propertyInfo
   */
  async function loadArrears(propertyInfo) {
    const propertyIds = propertyInfo.map((item) => {
      return item.id;
    });
    // let filter = '"invoice_type":"rent","status":"unpaid","balance":"gte::0"';
    let filter = `"status":"in::unpaid,partially_paid","invoice_date":"lte::${moment(lastDay, 'YYYY-MM-DD').format('YYYY-MM-DD')}"`;
    if (user.type !== 'admin') {
      filter = `${filter},"payee_id":"in::${propertyIds.join()}"`;
    }
    const urlInvoices = `${process.env.REACT_APP_API_URL}invoices?where={${filter}}`;
    try {
      const {
        data: { response },
      } = await axios.get(urlInvoices, requestConfig);
      let totalBalance = 0;
      response.forEach((item) => {
        const { balance } = item;
        totalBalance += Number(balance);
      });
      setArrears(totalBalance);
    } catch (_) {
      //
    }
  }

  /**
   *
   * @param {[]} unitInfo
   * @param {[]} tenantsInfo
   */
  async function loadTenancy(unitInfo, tenantsInfo) {
    const $incoming = tenantsInfo.filter((item) => {
      return (
        new Date(item.createdat) >= firstDay &&
        new Date(item.createdat) <= lastDay
      );
    });
    const unitIds = unitInfo.map((item) => {
      return item.id;
    });
    if (unitIds.length > 0) {
      let filter = `"status":"vacated","lastupdated":"btwn::${DateTimeUTC(firstDay, false)},${DateTimeUTC(lastDay, true)}"`;
      if (user.type !== 'admin') {
        filter = `${filter},"unit_id":"in::${unitIds.join()}"`;
      }
      const urlTenants = `${process.env.REACT_APP_API_URL}tenants?where={${filter}}`;
      try {
        const {
          data: { response },
        } = await axios.get(urlTenants, requestConfig);
        setOutgoing(response);
        setIncoming($incoming);
      } catch (_) {
        //
      }
    }
  }

  return (
    <Master title="Home" redirectLink="dashboard" source="Dashboard">
      <div className="row">
        <RedCard
          icon="graph3"
          amount={`Ksh ${monthRent.toLocaleString()}`}
          caption="Received this"
          strongText="Month"
        />
        <RedCard
          icon="graph1"
          amount={`Ksh ${todayRent.toLocaleString()}`}
          caption="Received"
          strongText="Today"
        />
        <StatsTile
          icon="users"
          amount={`Ksh ${arrears.toLocaleString()}`}
          caption="Outstanding rent"
          bodyText="Arrears"
        />
        <StatsTile
          icon="culture"
          amount={`${occupancy} % (${totalOccupied}/${totalUnits})`}
          bodyText="Occupancy"
          caption="Fully occupied"
        />
      </div>
      <div className="row">
        {(properties.length > 0 && tenants.length > 0 && units.length > 0) && (
          <TransLogs properties={properties} tenants={tenants} units={units} />
        )}
        <Tenancy caption={`${incoming.length} vs ${outgoing.length}`} />
        {properties.length > 0 && <Charges properties={properties} />}
      </div>
    </Master>
  );
};

const mapStateToProps = (state) => {
  const newState = state;
  if (newState.user) {
    if (newState.user.user) {
      newState.user = {
        ...newState.user.user,
      };
    }
  }
  return newState;
};

Dashboard.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

Dashboard.defaultProps = {
  user: PropTypes.object,
};

export default connect(mapStateToProps)(Dashboard);
